import React from 'react'
import images from '../images/website-building-3839578-3202880.png'
import logo from '../images/CALogo.svg'

const Commingsoon = () => {
  return (
    <>

       <div className='w-auto min-vh-100 p-2 text-white'  style={{ background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)"}} >
            <div className='pt-4 mt-1 align-item-center justify-content-center'>
                <div className='container-sm  text-center align-items-center justify-content-center' >
                    <div  className='d-flex align-items-center justify-content-center'>

                 
                        <img src={logo} className='img-fluid' width='150' height='' alt='logo'/>
                    </div>
                    <div className='d-block align-items-center justify-content-center'>
                        
                        
                        <p className='display-3'  style={{fontFamily:"Merienda"}}>We will be there in a while...</p>
                        <p className='lead fs-4 text-wrap'>Best solutions come from gathering new insights and pushing conventional boundaries </p>
                    </div>
                    <div className='' >
                        <img src={images} className='img-fluid' width='550' height='450' alt='maintanence'></img>
                    </div>
                </div > 

            </div>
           
        <footer className='text-center text-muted' >
       <p><small>© 2024 Copyright - CloudArsitek</small></p> 
        </footer>
       </div>
    </>
  )
}

export default Commingsoon