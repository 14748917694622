import React from 'react';
import notFound from '../images/—Pngtree—error 404 page not found_6681621.png';
import { Link } from 'react-router-dom';
import logo from '../images/CALogo.svg'
const page404 = () => {
  return (
   <>
    <div className='w-auto min-vh-100 p-2 text-white'  style={{ background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)"}} >
      <div className='container-sm mt-2 d-block align-items-center justify-content-center text-center'>
        
        <div  className='d-flex align-items-center  justify-content-center '>              
          <img src={logo} className='img-fluid' width='100' height='' alt='logo'/>
          <p className='glow mb-0 px-3 fs-1'>CLOUD ARSITEK</p>
        </div>

        <div>
          <img src={notFound} className='img-fluid' width='450' height='' alt='notfound' loading='lazy'/>
          <p className='display-1 text-bold'>Sorry , 
          This page isn't available.</p>
          <p className='lead'>The link you followed may be broken or the page may have been removed.</p>
        </div>
        <div className='pt-2'>
         

          <Link className='link-primary link-underline-opacity-50 link-offset-3 fw-bold' style={{  "textShadow": "0px 1px 3px #23430C"}} to={"/"} ><a href='/'><button  type="button" className="btn btn-primary shadow-lg   rounded">Back to HOME</button></a></Link>
         
        </div>
      </div>

      <footer className='text-center text-muted' >
       <p><small>© 2024 Copyright - CloudArsitek</small></p> 
        </footer>
    </div>
   </>
  )
}

export default page404