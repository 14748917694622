import React from 'react';
import './App.css';
import { BrowserRouter , Routes , Route} from 'react-router-dom'
import Commingsoon from './Components/commingsoon';
import Page404 from './Components/page404';

const Home = React.lazy(() => import("./Components/home.js"))


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Commingsoon/>}/>
          <Route path='*' element={<Page404/>}/>
          <Route path='/home' element={<Home />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
